import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import { fetchDataSub } from "./redux/datasub/datasubActions";

import * as s from "./styles/globalStyles";
import styled from "styled-components";
// import { AudioPlayer } from "./AudioPlayer";
import Header from "./components/Header";
import About from "./components/About";
import Roadmap from "./components/Roadmap";
import Member from "./components/Member";
import Faq from "./components/Faq";
import Partners from "./components/Partners";
import Footer from "./components/Footer";
import CircleTextLogoLeft from "./components/CircleTextLogoLeft";
import CircleTextLogoRight from "./components/CircleTextLogoRight";
import useMedia from "use-media";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const MintTitle = styled.p`
  color: white;
  font-size: 30px;
  font-family: DelaGothicOne;
  font-weight: 500;
  line-height: 1.6;
`;

export const StyledInput = styled.input`
  color: #777;
  font-size: 2rem;
  border-radius: 5px;
  padding: 0.6rem 0.6rem;
  width: 50%;
  position: relative;
  overflow: hidden;
`;

export const StyledButton = styled.button`
  color: #fff;
  font-size: 2rem;
  font-family: DelaGothicOne;
  border-radius: 50px;
  padding: 0.6rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: #ff0000;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-135deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;

export const StyledButtonOparator = styled.button`
  color: #fff;
  font-size: 0.8rem;
  display: inline-block;
  border-radius: 10px;
  padding: 10px 0;
  position: relative;
  overflow: hidden;
  width: 20vw;
  background-color: #ff0000;
`;

export const StyledButtonOparatorOff = styled.button`
  color: #fff;
  font-size: 1rem;
  display: inline-block;
  border-radius: 10px;
  padding: 10px 0;
  position: relative;
  overflow: hidden;
  background-color: #333;
  width: 20vw;
  /* background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow); */
  background-size: 700% 700%;
`;
export const StyledIncDecButton = styled.button`
  font-family: DelaGothicOne;
  padding: 10px;
  border-radius: 2px;
  margin: 0 2px;
  border: solid 1px #333;
  background-color: rgba(255, 255, 255, 0.4);
  padding: 20px;
  font-weight: bold;
  font-size: 15px;
  color: #333;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;
export const StyledButtonControl = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #333;
  padding: 10px;
  font-weight: bold;
  color: white;
  width: 20%;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonReload = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #333;
  padding: 10px;
  font-weight: bold;
  color: white;
  width: 50%;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;
export const StyledButtonA = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--button-bg);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonWl = styled.button`
  color: #fff;
  font-size: 2rem;
  font-family: DelaGothicOne;
  border-radius: 10px;
  padding: 0.6rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: #ff0000;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
  cursor: pointer;

  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-135deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;

export const StyledButtonPS = styled.button`
  color: #fff;
  font-size: 2rem;
  font-family: DelaGothicOne;
  border-radius: 50px;
  padding: 0.6rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: #ff0000;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
  cursor: pointer;

  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-135deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;

export const StyledButtonUnlock = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: purple;
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  font-family: DelaGothicOne;
  border-radius: 100%;
  border: none;
  background-color: #fff;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  display: none;
  @media (min-width: 900px) {
    width: 250px;
    display: block;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const datasub = useSelector((state) => state.datasub);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [mintAmount, setMintAmount] = useState(1);
  const [mintAmountWl, setmintAmountWl] = useState(1);
  const [mintAmountWl1, setmintAmountWl1] = useState(1);
  const [mintAmountWl2, setmintAmountWl2] = useState(1);
  const [mintAmountWl3, setmintAmountWl3] = useState(1);

  const [minted, setminted] = useState(0);
  const [mintedWL, setmintedWL] = useState(0);
  const [mintedWL1, setmintedWL1] = useState(0);
  const [mintedWL2, setmintedWL2] = useState(0);
  const [mintedWL3, setmintedWL3] = useState(0);
  const [mintedAL, setmintedAL] = useState(0);

  // const [canMint, setcanMint] = useState(0);
  const [canMint1, setcanMint1] = useState(0);
  const [canMint2, setcanMint2] = useState(0);
  const [canMint3, setcanMint3] = useState(0);
  const [mintable, setMintable] = useState(0);
  const [mintable1, setMintable1] = useState(0);
  const [mintable2, setMintable2] = useState(0);
  const [mintable3, setMintable3] = useState(0);
  const [wl, setwl] = useState(false);
  const [lang, setlang] = useState(false);
  const [phaseId, setphaseId] = useState(0);

  // const [whitelistProof, setwhitelistProof] = useState("");
  // const [whitelistValid, setwhitelistValid] = useState(false);

  // const [allowlistProof, setAllowlistProof] = useState(["", "", ""]);
  // const [allowlistValid, setAllowlistValid] = useState([false, false, false]);
  const [whitelistProof1, setwhitelistProof1] = useState("");
  const [whitelistValid1, setwhitelistValid1] = useState(false);
  const [whitelistProof2, setwhitelistProof2] = useState("");
  const [whitelistValid2, setwhitelistValid2] = useState(false);
  const [whitelistProof3, setwhitelistProof3] = useState("");
  const [whitelistValid3, setwhitelistValid3] = useState(false);

  const [revealed, setrevealed] = useState(false);
  const [wlMaxMintNum, setwlMaxMintNum] = useState(3);
  const isNotSmp = useMedia({ minWidth: "500px" });
  const [langData, setlangData] = useState({
    fail: "Failed,Please try again.",
    chkops: "you minted PNL! check on your Opensea.",
    minting: "Minting PNL....",
    salestartal1: "AL1 Stage：03/25(Sat) 20:00〜(JST)",
    salestartal2: "AL2 Stage：03/25(Sat) 21:00〜(JST)",
    salestartal3: "AL3 Stage：03/25(Sat) 22:00〜(JST)",
    salestartwl: "WL：02/18(Sat) 22:30〜(JST)",
    salestartpb: "Public Sale：02/18(Sat) 23:30〜(JST)",
    soldout: "Sold Out! thank you!",
    wl: "Presale",
    pb: "Public Sale",
    gasfee: "+gas Fee",
    network: "to get PNL,Please Connect to Ethereum Mainnet.",
    busy: "Busy",
    minted: "minted",
    youhaveal1: "You are on the AL1 list!",
    youhaveal2: "You are on the AL2 list!",
    youhaveal3: "You are on the AL3 list!",
    youhavewl: "You are on the Whitelist!",
    youhavenotwl: "You are not have AL.",
    mintwl: "mintWL",
    mintps: "mintPS",
    reload: "reload",
  });

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1650,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    DISPLAY_COSTWL: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  // const handleClick = (url, filename) => {
  //   alert("読みこむので少々お待ちください。");
  //   axios
  //     .get(url, {
  //       responseType: "blob",
  //     })
  //     .then((res) => {
  //       fileDownload(res.data, filename);
  //     });
  // };

  const claimNFTsWl = async (_phaseId) => {
    let cost = CONFIG.WEI_COSTWL;
    mintAmountWl;
    console.log("amount::" + String(mintAmountWl));
    let amount;
    let whitelistProof;
    let canMint;
    switch (_phaseId) {
      case 0:
        amount = Number(mintAmountWl1);
        whitelistProof = whitelistProof1;
        canMint = canMint1;
        break;
      case 1:
        amount = Number(mintAmountWl2);
        whitelistProof = whitelistProof2;
        canMint = canMint2;
        break;
      case 2:
        amount = Number(mintAmountWl3);
        whitelistProof = whitelistProof3;
        canMint = canMint3;
        break;
      default:
        console.log("not found PhaseID");
    }

    // let amount = mintAmountWl;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * amount); //個数を１に固定0714(ふりっきー)
    let gasMulti = 1;
    // gasMulti = 0.7 * amount;
    // gasMulti += 1;
    let totalGasLimit = String(gasLimit * gasMulti); //個数を1に固定0714(ふりっきー)
    let method = await blockchain.smartContract.methods.whitelistMint(
      _phaseId,
      amount,
      canMint,
      whitelistProof
    );
    let gas = await method.estimateGas({
      from: blockchain.account,
      value: totalCostWei,
    });
    console.log("estimateGas: " + gas);
    let gasPrice = await blockchain.web3.eth.getGasPrice();
    gasPrice = Math.round(gasPrice * 1.2);
    console.log("gasPrice: " + gasPrice);

    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`${CONFIG.NFT_NAME}${langData.minting}`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .whitelistMint(_phaseId, amount, canMint, whitelistProof)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        gasPrice: gasPrice,
        gas: gas + 50000,
        // maxPriorityFeePerGas: "40000000000",
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback(langData.fail);
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(langData.chkops);
        setClaimingNft(false);
        checkmintedWL(_phaseId);
        // dispatch(fetchData(blockchain.account));
      });
  };

  const claimNFTsAl = (amount, _phaseId) => {
    let cost = CONFIG.WEI_COSTWL;
    console.log("amount::" + String(amount));
    amount = Number(amount);
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * amount); //個数を１に固定0714(ふりっきー)
    let gasMulti = 1;
    gasMulti = 0.7 * amount;
    gasMulti += 1;
    let totalGasLimit = String(gasLimit * gasMulti); //個数を1に固定0714(ふりっきー)
    let allowlistProof = "";
    switch (_phaseId) {
      case 0:
        allowlistProof = allowlistProof1;
        break;
      case 1:
        allowlistProof = allowlistProof2;
        break;
      case 2:
        allowlistProof = allowlistProof3;
        break;
      default:
        console.log("not found PhaseID");
    }
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`${CONFIG.NFT_NAME}${langData.minting}`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .allowlistMint(amount, allowlistProof)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        // maxPriorityFeePerGas: "40000000000",
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback(langData.fail);
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(langData.chkops);
        setClaimingNft(false);
        checkmintedAL();
        // dispatch(fetchData(blockchain.account));
      });
  };

  const setReveal = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setReveal(10000)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にリビールされました！openseaでご確認ください。`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const setALSaleEnable = (flagBool) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setAllowlistSaleEnable(flagBool)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        {
          flagBool
            ? setFeedback("正常にALセール開始されました！")
            : setFeedback("正常にALセール停止されました！");
        }
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const setWLSaleEnable = (_phaseId, flagBool) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`AL1.SALE 開始します ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setWhitelistSaleEnable(_phaseId, flagBool)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        // console.log(receipt);
        {
          flagBool
            ? setFeedback("正常にAL1セール開始されました！")
            : setFeedback("正常にAL1セール停止されました！");
        }
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const setPhaseId = (_phaseId) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`PHASEID.${_phaseId} セットします ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setPhaseIdWithReset(_phaseId)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        // console.log(receipt);
        switch (_phaseId) {
          case 0:
            setFeedback("正常にAL1 2ndセール開始されました！");
            break;
          case 1:
            setFeedback("正常にAL2セール開始されました！");
            break;
          case 2:
            setFeedback("正常にWLセール開始されました！");
            break;
          default:
            console.log("not found PhaseID");
        }

        setClaimingNft(false);
        checkmintedAL();
        checkphaseId();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const withdraw = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`出金中 ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .withdraw()
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常に出金されました！`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const changeLang = () => {
    setlang(!lang);
    let langJson = require("./data/jp.json");
    if (lang) {
      langJson = require("./data/en.json");
    }
    setlangData(langJson);
    initData();
  };

  const getWhitelist = (_phaseId) => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      let address = blockchain.account.toLowerCase();
      let { MerkleTree } = require("merkletreejs");
      let keccak256 = require("keccak256");

      let whitelist = require("./data/al" + _phaseId + ".json");

      let validNuns = whitelist.map((addr) => addr.slice(-64));
      let adresses = whitelist.map((addr) => addr.slice(0, -64));

      let index = adresses.indexOf(address);
      let validNun = validNuns[index];

      let mintNum = 0;
      if (validNun !== undefined) {
        // mintNum = validNun.slice(-1);
        mintNum = parseInt(validNun, 16);
      }

      let hashedAddresses = whitelist.map((addr) => keccak256(addr));
      let merkleTree = new MerkleTree(hashedAddresses, keccak256, {
        sortPairs: true,
      });
      let hashedAddress = keccak256(address + validNun);
      let proof = merkleTree.getHexProof(hashedAddress);
      let root = merkleTree.getHexRoot();
      // console.log('VIP:address/'+address+':::mintNum/'+mintNum);
      // console.log('VIP:hasheBefore/'+address+validNun);
      // console.log('VIP:hashed/'+hashedAddress);

      blockchain.smartContract.methods
        .isWhitelisted(blockchain.account, _phaseId, mintNum, proof)
        .call()
        .then((receipt) => {
          switch (_phaseId) {
            case 0:
              setwhitelistProof1(proof);
              setwhitelistValid1(receipt);
              setcanMint1(mintNum);
              if (canMint1 > 0) {
                setMintable1(canMint1 - mintedWL1);
              }
              console.log("WL" + _phaseId + ":proof/" + whitelistProof1);
              break;
            case 1:
              setwhitelistProof2(proof);
              setwhitelistValid2(receipt);
              setcanMint2(mintNum);
              if (canMint2 > 0) {
                setMintable2(canMint2 - mintedWL2);
              }
              console.log("WL" + _phaseId + ":proof/" + whitelistProof2);
              break;
            case 2:
              setwhitelistProof3(proof);
              setwhitelistValid3(receipt);
              setcanMint3(mintNum);
              if (canMint3 > 0) {
                setMintable3(canMint3 - mintedWL3);
              }
              console.log("WL" + _phaseId + ":proof/" + whitelistProof3);
              break;
            default:
              console.log("not found PhaseID");
          }

          // console.log('viplistValid/'+viplistValid);
          dispatch(fetchData(blockchain.account));
        });
      // setwhitelistProof(proof);
      // setwhitelistValid(valid);
      // console.log("WL:wallet/" + blockchain.account);
      // console.log("WL:proof/" + proof);
      switch (_phaseId) {
        case 0:
          console.log("WL1" + _phaseId + ":proof/" + whitelistProof1);
          break;
        case 1:
          console.log("WL2" + _phaseId + ":proof/" + whitelistProof2);
          break;
        case 2:
          console.log("WL3" + _phaseId + ":proof/" + whitelistProof3);
          break;
        default:
          console.log("not found PhaseID");
      }
      console.log("WL" + _phaseId + ":root/" + root);
      // console.log(whitelistValid);
    }
  };

  const checkRevealed = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .revealed()
        .call()
        .then((receipt) => {
          setrevealed(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const checkphaseId = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .getPhaseId()
        .call()
        .then((receipt) => {
          setphaseId(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };
  const checkMinted = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .psMinted(blockchain.account)
        .call()
        .then((receipt) => {
          setminted(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const checkmintedWL = (_phaseId) => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .wlMinted(blockchain.account, _phaseId)
        .call()
        .then((receipt) => {
          switch (_phaseId) {
            case 0:
              setmintedWL1(receipt);
              break;
            case 1:
              setmintedWL2(receipt);
              break;
            case 2:
              setmintedWL3(receipt);
              break;
            default:
              console.log("not found PhaseID");
          }

          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const checkmintedAL = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .alMinted(blockchain.account)
        .call()
        .then((receipt) => {
          setmintedAL(receipt);
          // console.log("mintedAL:::" + receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const decrementmintAmountWl1 = (diffNum) => {
    let newmintAmountWl1 = mintAmountWl1 - diffNum;
    if (newmintAmountWl1 < 1) {
      newmintAmountWl1 = 1;
    }
    setmintAmountWl1(newmintAmountWl1);
  };

  const incrementmintAmountWl1 = (diffNum) => {
    let newmintAmountWl1 = mintAmountWl1 + diffNum;
    let maxNum = canMint1 - mintedWL1;
    if (newmintAmountWl1 > maxNum) {
      newmintAmountWl1 = maxNum;
    }
    setmintAmountWl1(newmintAmountWl1);
  };

  const decrementmintAmountWl2 = (diffNum) => {
    let newmintAmountWl2 = mintAmountWl2 - diffNum;
    if (newmintAmountWl2 < 1) {
      newmintAmountWl2 = 1;
    }
    setmintAmountWl2(newmintAmountWl2);
  };

  const incrementmintAmountWl2 = (diffNum) => {
    let newmintAmountWl2 = mintAmountWl2 + diffNum;
    let maxNum = canMint2 - mintedWL2;
    if (newmintAmountWl2 > maxNum) {
      newmintAmountWl2 = maxNum;
    }
    setmintAmountWl2(newmintAmountWl2);
  };

  const decrementmintAmountWl3 = (diffNum) => {
    let newmintAmountWl3 = mintAmountWl3 - diffNum;
    if (newmintAmountWl3 < 1) {
      newmintAmountWl3 = 1;
    }
    setmintAmountWl3(newmintAmountWl3);
  };

  const incrementmintAmountWl3 = (diffNum) => {
    let newmintAmountWl3 = mintAmountWl3 + diffNum;
    let maxNum = canMint3 - mintedWL3;
    if (newmintAmountWl3 > maxNum) {
      newmintAmountWl3 = maxNum;
    }
    setmintAmountWl3(newmintAmountWl3);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };
  const getDataSub = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchDataSub(blockchain.account));
    }
    // console.dir(datasub);
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const getReload = () => {
    location.reload();
  };

  const initData = () => {
    getConfig();
    checkMinted();
    checkmintedWL(0);
    checkmintedWL(1);
    checkmintedWL(2);
    checkmintedAL();
    getWhitelist(0);
    getWhitelist(1);
    getWhitelist(2);
    checkphaseId();
    checkRevealed();
  };

  useEffect(() => {
    getConfig();
    checkMinted();
    checkmintedWL(0);
    checkmintedWL(1);
    checkmintedWL(2);
    checkmintedAL();
    getWhitelist(0);
    getWhitelist(1);
    getWhitelist(2);
    checkphaseId();
    checkRevealed();
  }, []);

  useEffect(() => {
    getData();
    getDataSub();
    checkMinted();
    checkmintedWL(0);
    checkmintedWL(1);
    checkmintedWL(2);
    checkmintedAL();
    checkRevealed();
    getWhitelist(0);
    getWhitelist(1);
    getWhitelist(2);
    checkphaseId();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <Header />

      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "rgba(0, 0, 0,0)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.jpeg" : null}
      >
        <ul class="bgtrans">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <MintTitle
          id="mint"
          style={{
            textAlign: "center",
          }}
        >
          {"MINT"}
        </MintTitle>

        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <CircleTextLogoLeft />
            {/* <StyledImg
              alt={"example"}
              src={"/config/images/example.gif"}
              style={{ transform: "scaleX(-1)" }}
            /> */}
          </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "rgba(0,0,0,0.4)",
              padding: 24,
              borderRadius: 24,
              border: "4px var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 20,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              LANG/言語
            </s.TextTitle>
            <div class="toggle-switch">
              <input
                id="toggleBox"
                class="toggle-input"
                type="checkbox"
                style={{ display: "none" }}
                onChange={(e) => {
                  // e.preventDefault();
                  changeLang();
                }}
              />
              <label for="toggleBox" class="toggle-label" />
            </div>
            <s.TextTitle style={{ textAlign: "center", color: "yellow" }}>
              {langData.salestartal1}
            </s.TextTitle>
            <s.TextTitle style={{ textAlign: "center", color: "yellow" }}>
              {langData.salestartal2}
            </s.TextTitle>
            <s.TextTitle style={{ textAlign: "center", color: "yellow" }}>
              {langData.salestartal3}
            </s.TextTitle>
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 20,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              {/* <s.SpacerXSmall />
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                      <AudioPlayer></AudioPlayer>
                </s.TextDescription> */}
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            >
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.TextDescription>
            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  {langData.soldout}
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  1 {CONFIG.SYMBOL} costs
                </s.TextTitle>
                <s.SpacerXSmall />

                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  {/* {langData.wl}
                  {" / "} */}
                  {CONFIG.DISPLAY_COSTWL}
                  {CONFIG.NETWORK.SYMBOL}
                  {/* {`(Max ${datasub.maxMintsPerWL}mint)`} */}
                </s.TextTitle>
                {/* <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  {langData.pb}
                  {" / "}
                  {CONFIG.DISPLAY_COST}
                  {CONFIG.NETWORK.SYMBOL}
                  {`(Max ${datasub.maxMintsPerPS}mint)`}
                </s.TextTitle> */}
                <s.SpacerXSmall />

                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  {langData.gasfee}
                </s.TextDescription>
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {langData.network}
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect(lang));
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.Container>
                      <s.Container>
                        {/* AL1ここから */}
                        {whitelistValid1 ? (
                          <>
                            {/* {data.isPsMPEnabled || data.isPsEnabled ? (
                              <></>
                            ) : (
                              <> */}
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                              <s.TextDescription
                                style={{
                                  color: "var(--accent-text)",
                                  fontFamily: "DelaGothicOne",
                                  fontSize: isNotSmp ? "1.5rem" : "1.1rem",
                                }}
                              >
                                {langData.youhaveal1 + "(max:" + canMint1 + ")"}
                              </s.TextDescription>
                            </s.Container>
                            {/* </>
                            )} */}

                            {data.isWlEnabled1 ? (
                              <>
                                <s.Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"row"}
                                >
                                  <s.TextDescription
                                    style={{
                                      color: "var(--accent-text)",
                                      fontFamily: "DelaGothicOne",
                                      fontSize: isNotSmp ? "1.5rem" : "1.1rem",
                                    }}
                                  >
                                    {"AL1 Now On Sale!"}
                                  </s.TextDescription>
                                </s.Container>
                                {canMint1 <= mintedWL1 ? (
                                  <>
                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <StyledButtonWl
                                        disabled={1} //claimingNftPsがtrueなら disabledを表示させる。＝クリックできない
                                        onClick={(e) => {
                                          e.preventDefault();
                                        }}
                                      >
                                        {langData.minted}
                                      </StyledButtonWl>
                                    </s.Container>
                                  </>
                                ) : (
                                  <>
                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <StyledIncDecButton
                                        style={{ lineHeight: 0.4 }}
                                        disabled={claimingNft ? 1 : 0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          decrementmintAmountWl1(canMint1);
                                          getData();
                                        }}
                                      >
                                        -{canMint1}
                                      </StyledIncDecButton>
                                      <StyledIncDecButton
                                        style={{ lineHeight: 0.4 }}
                                        disabled={claimingNft ? 1 : 0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          decrementmintAmountWl1(1);
                                          getData();
                                        }}
                                      >
                                        -1
                                      </StyledIncDecButton>
                                      <s.SpacerMedium />
                                      <s.TextDescription
                                        style={{
                                          textAlign: "center",
                                          color: "var(--accent-text)",
                                          padding: 20,
                                        }}
                                      >
                                        {mintAmountWl1}
                                      </s.TextDescription>
                                      <s.SpacerMedium />
                                      <StyledIncDecButton
                                        disabled={claimingNft ? 1 : 0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          incrementmintAmountWl1(1);
                                          getData();
                                        }}
                                      >
                                        +1
                                      </StyledIncDecButton>
                                      <StyledIncDecButton
                                        disabled={claimingNft ? 1 : 0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          incrementmintAmountWl1(canMint1);
                                          getData();
                                        }}
                                      >
                                        +{canMint1}
                                      </StyledIncDecButton>
                                    </s.Container>
                                    <s.SpacerSmall />
                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <StyledButton
                                        disabled={claimingNft ? 1 : 0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          claimNFTsWl(0);
                                          getData();
                                        }}
                                      >
                                        {claimingNft
                                          ? "BUSY"
                                          : mintAmountWl1 + " MINT"}
                                      </StyledButton>
                                    </s.Container>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {/* <s.SpacerMedium />
                                <s.TextDescription
                                  style={{
                                    textAlign: "center",
                                    color: "var(--accent-text)",
                                  }}
                                >
                                  {"AL1は開始していません"}
                                </s.TextDescription> */}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {/* <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {"このアドレスはAL1に含まれていません。"}
                            </s.TextDescription> */}
                          </>
                        )}
                        {/* AL1ここまで */}

                        {/* AL２ここから */}
                        {whitelistValid2 ? (
                          <>
                            {/* {data.isPsMPEnabled || data.isPsEnabled ? (
                              <></>
                            ) : (
                              <> */}
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                              <s.TextDescription
                                style={{
                                  color: "var(--accent-text)",
                                  fontFamily: "DelaGothicOne",
                                  fontSize: isNotSmp ? "1.5rem" : "1.1rem",
                                }}
                              >
                                {langData.youhaveal2 + "(max:" + canMint2 + ")"}
                              </s.TextDescription>
                            </s.Container>
                            {/* </>
                            )} */}

                            {data.isWlEnabled2 ? (
                              <>
                                <s.Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"row"}
                                >
                                  <s.TextDescription
                                    style={{
                                      color: "var(--accent-text)",
                                      fontFamily: "DelaGothicOne",
                                      fontSize: isNotSmp ? "1.5rem" : "1.1rem",
                                    }}
                                  >
                                    {"AL2 Now On Sale!"}
                                  </s.TextDescription>
                                </s.Container>
                                {canMint2 <= mintedWL2 ? (
                                  <>
                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <StyledButtonWl
                                        disabled={1} //claimingNftPsがtrueなら disabledを表示させる。＝クリックできない
                                        onClick={(e) => {
                                          e.preventDefault();
                                        }}
                                      >
                                        {langData.minted}
                                      </StyledButtonWl>
                                    </s.Container>
                                  </>
                                ) : (
                                  <>
                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <StyledIncDecButton
                                        style={{ lineHeight: 0.4 }}
                                        disabled={claimingNft ? 1 : 0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          decrementmintAmountWl2(canMint2);
                                          getData();
                                        }}
                                      >
                                        -{canMint2}
                                      </StyledIncDecButton>
                                      <StyledIncDecButton
                                        style={{ lineHeight: 0.4 }}
                                        disabled={claimingNft ? 1 : 0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          decrementmintAmountWl2(1);
                                          getData();
                                        }}
                                      >
                                        -1
                                      </StyledIncDecButton>
                                      <s.SpacerMedium />
                                      <s.TextDescription
                                        style={{
                                          textAlign: "center",
                                          color: "var(--accent-text)",
                                          padding: 20,
                                        }}
                                      >
                                        {mintAmountWl2}
                                      </s.TextDescription>
                                      <s.SpacerMedium />
                                      <StyledIncDecButton
                                        disabled={claimingNft ? 1 : 0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          incrementmintAmountWl2(1);
                                          getData();
                                        }}
                                      >
                                        +1
                                      </StyledIncDecButton>
                                      <StyledIncDecButton
                                        disabled={claimingNft ? 1 : 0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          incrementmintAmountWl2(canMint2);
                                          getData();
                                        }}
                                      >
                                        +{canMint2}
                                      </StyledIncDecButton>
                                    </s.Container>
                                    <s.SpacerSmall />
                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <StyledButton
                                        disabled={claimingNft ? 1 : 0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          claimNFTsWl(1);
                                          getData();
                                        }}
                                      >
                                        {claimingNft
                                          ? "BUSY"
                                          : mintAmountWl2 + " MINT"}
                                      </StyledButton>
                                    </s.Container>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {/* <s.SpacerMedium />
                                <s.TextDescription
                                  style={{
                                    textAlign: "center",
                                    color: "var(--accent-text)",
                                  }}
                                >
                                  {"AL1は開始していません"}
                                </s.TextDescription> */}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {/* <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {"このアドレスはAL1に含まれていません。"}
                            </s.TextDescription> */}
                          </>
                        )}
                        {/* AL２ここまで */}

                        {/* AL3ここから */}
                        {whitelistValid3 ? (
                          <>
                            {/* {data.isPsMPEnabled || data.isPsEnabled ? (
                              <></>
                            ) : (
                              <> */}
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                              <s.TextDescription
                                style={{
                                  color: "var(--accent-text)",
                                  fontFamily: "DelaGothicOne",
                                  fontSize: isNotSmp ? "1.5rem" : "1.1rem",
                                }}
                              >
                                {langData.youhaveal3 + "(max:" + canMint3 + ")"}
                              </s.TextDescription>
                            </s.Container>
                            {/* </>
                            )} */}

                            {data.isWlEnabled3 ? (
                              <>
                                <s.Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"row"}
                                >
                                  <s.TextDescription
                                    style={{
                                      color: "var(--accent-text)",
                                      fontFamily: "DelaGothicOne",
                                      fontSize: isNotSmp ? "1.5rem" : "1.1rem",
                                    }}
                                  >
                                    {"AL3 Now On Sale!"}
                                  </s.TextDescription>
                                </s.Container>
                                {canMint3 <= mintedWL3 ? (
                                  <>
                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <StyledButtonWl
                                        disabled={1} //claimingNftPsがtrueなら disabledを表示させる。＝クリックできない
                                        onClick={(e) => {
                                          e.preventDefault();
                                        }}
                                      >
                                        {langData.minted}
                                      </StyledButtonWl>
                                    </s.Container>
                                  </>
                                ) : (
                                  <>
                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <StyledIncDecButton
                                        style={{ lineHeight: 0.4 }}
                                        disabled={claimingNft ? 1 : 0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          decrementmintAmountWl3(canMint3);
                                          getData();
                                        }}
                                      >
                                        -{canMint3}
                                      </StyledIncDecButton>
                                      <StyledIncDecButton
                                        style={{ lineHeight: 0.4 }}
                                        disabled={claimingNft ? 1 : 0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          decrementmintAmountWl3(1);
                                          getData();
                                        }}
                                      >
                                        -1
                                      </StyledIncDecButton>
                                      <s.SpacerMedium />
                                      <s.TextDescription
                                        style={{
                                          textAlign: "center",
                                          color: "var(--accent-text)",
                                          padding: 20,
                                        }}
                                      >
                                        {mintAmountWl3}
                                      </s.TextDescription>
                                      <s.SpacerMedium />
                                      <StyledIncDecButton
                                        disabled={claimingNft ? 1 : 0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          incrementmintAmountWl3(1);
                                          getData();
                                        }}
                                      >
                                        +1
                                      </StyledIncDecButton>
                                      <StyledIncDecButton
                                        disabled={claimingNft ? 1 : 0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          incrementmintAmountWl3(canMint3);
                                          getData();
                                        }}
                                      >
                                        +{canMint3}
                                      </StyledIncDecButton>
                                    </s.Container>
                                    <s.SpacerSmall />
                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <StyledButton
                                        disabled={claimingNft ? 1 : 0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          claimNFTsWl(2);
                                          getData();
                                        }}
                                      >
                                        {claimingNft
                                          ? "BUSY"
                                          : mintAmountWl3 + " MINT"}
                                      </StyledButton>
                                    </s.Container>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {/* <s.SpacerMedium />
                                <s.TextDescription
                                  style={{
                                    textAlign: "center",
                                    color: "var(--accent-text)",
                                  }}
                                >
                                  {"AL1は開始していません"}
                                </s.TextDescription> */}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {/* <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {"このアドレスはAL1に含まれていません。"}
                            </s.TextDescription> */}
                          </>
                        )}
                        {/* AL3ここまで */}

                        {/* AWL保有してない方 */}
                        {whitelistValid1 ||
                        whitelistValid2 ||
                        whitelistValid3 ? (
                          <></>
                        ) : (
                          <>
                            {" "}
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                              <s.TextDescription
                                style={{
                                  color: "var(--accent-text)",
                                  fontFamily: "DelaGothicOne",
                                  fontSize: isNotSmp ? "1.5rem" : "1.1rem",
                                }}
                              >
                                {langData.youhavenotwl}
                              </s.TextDescription>
                            </s.Container>
                          </>
                        )}
                        {/* AWL保有してない方 */}
                      </s.Container>
                    </s.Container>
                    <s.SpacerLarge />

                    <StyledButtonReload
                      onClick={(e) => {
                        e.preventDefault();
                        getReload();
                      }}
                    >
                      {claimingNft ? "Busy" : langData.reload}
                    </StyledButtonReload>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <CircleTextLogoRight />
            {/* <StyledImg
              alt={"example"}
              src={"/config/images/example.gif"}
              style={{ transform: "scaleX(-1)" }}
            /> */}
          </s.Container>
        </ResponsiveWrapper>
      </s.Container>
      {/* <About id="about" /> */}
      {/* <Roadmap id="roadmap" /> */}
      {/* <Member id="member" /> */}

      {/* オペレーターコマンド */}
      {blockchain.account === "" ||
      blockchain.smartContract === null ||
      blockchain.account === undefined ? (
        <></>
      ) : (
        <>
          {datasub.deployer == blockchain.account ? (
            <>
              <div
                // ai={"center"}
                style={
                  {
                    // display: "flex",
                    // justifyContent: "center",
                  }
                }
              >
                {!data.isWlEnabled1 ? (
                  <StyledButtonOparator
                    onClick={(e) => {
                      e.preventDefault();
                      setWLSaleEnable(0, true);
                      getData();
                    }}
                  >
                    {claimingNft ? "Busy" : "WL1.Start"}
                  </StyledButtonOparator>
                ) : (
                  <>
                    <StyledButtonOparatorOff
                      onClick={(e) => {
                        e.preventDefault();
                        setWLSaleEnable(0, false);
                        getData();
                      }}
                    >
                      {claimingNft ? "Busy" : "WL1.Stop"}
                    </StyledButtonOparatorOff>
                  </>
                )}
                {!data.isWlEnabled2 ? (
                  <StyledButtonOparator
                    onClick={(e) => {
                      e.preventDefault();
                      setWLSaleEnable(1, true);
                      getData();
                    }}
                  >
                    {claimingNft ? "Busy" : "WL2.Start"}
                  </StyledButtonOparator>
                ) : (
                  <>
                    <StyledButtonOparatorOff
                      onClick={(e) => {
                        e.preventDefault();
                        setWLSaleEnable(1, false);
                        getData();
                      }}
                    >
                      {claimingNft ? "Busy" : "WL2.Stop"}
                    </StyledButtonOparatorOff>
                  </>
                )}
                {!data.isWlEnabled3 ? (
                  <StyledButtonOparator
                    onClick={(e) => {
                      e.preventDefault();
                      setWLSaleEnable(2, true);
                      getData();
                    }}
                  >
                    {claimingNft ? "Busy" : "WL3.Start"}
                  </StyledButtonOparator>
                ) : (
                  <>
                    <StyledButtonOparatorOff
                      onClick={(e) => {
                        e.preventDefault();
                        setWLSaleEnable(2, false);
                        getData();
                      }}
                    >
                      {claimingNft ? "Busy" : "WL3.Stop"}
                    </StyledButtonOparatorOff>
                  </>
                )}

                {!data.isAlEnabled ? (
                  <StyledButtonOparator
                    onClick={(e) => {
                      e.preventDefault();
                      setALSaleEnable(true);
                      getData();
                    }}
                  >
                    {claimingNft ? "Busy" : "AL.Start"}
                  </StyledButtonOparator>
                ) : (
                  <>
                    <StyledButtonOparatorOff
                      onClick={(e) => {
                        e.preventDefault();
                        setALSaleEnable(false);
                        getData();
                      }}
                    >
                      {claimingNft ? "Busy" : "AL.Stop"}
                    </StyledButtonOparatorOff>
                    {/* AL開放中の場合フェーズIDが切り替えられる */}

                    {/* フェーズIDセット 0 */}
                    {phaseId != 0 ? (
                      <>
                        <StyledButtonOparator
                          onClick={(e) => {
                            e.preventDefault();
                            setPhaseId(0);
                            getData();
                          }}
                        >
                          {claimingNft ? "Busy" : "AL1.2nd"}
                        </StyledButtonOparator>
                      </>
                    ) : (
                      <>
                        <StyledButtonOparatorOff
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                          disabled={1}
                        >
                          {claimingNft ? "Busy" : "NOW.AL1.2nd"}
                        </StyledButtonOparatorOff>
                      </>
                    )}
                    {/* フェーズIDセット 1 */}
                    {phaseId != 1 ? (
                      <>
                        <StyledButtonOparator
                          onClick={(e) => {
                            e.preventDefault();
                            setPhaseId(1);
                            getData();
                          }}
                        >
                          {claimingNft ? "Busy" : "AL2"}
                        </StyledButtonOparator>
                      </>
                    ) : (
                      <>
                        <StyledButtonOparatorOff
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                          disabled={1}
                        >
                          {claimingNft ? "Busy" : "NOW.AL2"}
                        </StyledButtonOparatorOff>
                      </>
                    )}

                    {/* フェーズIDセット 2 */}
                    {phaseId != 2 ? (
                      <>
                        <StyledButtonOparator
                          onClick={(e) => {
                            e.preventDefault();
                            setPhaseId(2);
                            getData();
                          }}
                        >
                          {claimingNft ? "Busy" : "WL"}
                        </StyledButtonOparator>
                      </>
                    ) : (
                      <>
                        <StyledButtonOparatorOff
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                          disabled={1}
                        >
                          {claimingNft ? "Busy" : "NOW.WL"}
                        </StyledButtonOparatorOff>
                      </>
                    )}
                    {/* AL開放中の場合フェーズIDが切り替えられる */}
                  </>
                )}

                <StyledButtonOparator
                  onClick={(e) => {
                    e.preventDefault();
                    setBaseUrl();
                    getData();
                  }}
                >
                  {claimingNft ? "Busy" : "URL.Set"}
                </StyledButtonOparator>

                <StyledButtonOparator
                  onClick={(e) => {
                    e.preventDefault();
                    setReveal();
                    getData();
                  }}
                >
                  {claimingNft ? "Busy" : "REVEAL"}
                </StyledButtonOparator>

                <StyledButtonOparator
                  onClick={(e) => {
                    e.preventDefault();
                    withdraw();
                    getData();
                  }}
                >
                  {claimingNft ? "Busy" : "WITHDRAW"}
                </StyledButtonOparator>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      )}
      {/* オペレーターコマンド */}

      <Faq id="faq" />
      {/* <Partners id="partners" /> */}
      <Footer config={CONFIG} />
    </s.Screen>
  );
}

export default App;
