import { AppBar, Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import Box from "@mui/material/Box";
import * as s from "./../styles/globalStyles";
import React from "react";

export const BoxList = styled.div`
  background-color: #000;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const TextDescription = styled.p`
  color: white;
  font-size: 12px;
  line-height: 1.6;
`;

export const StyledFaq = styled.img`
  width: 100%;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const Question = styled.div`
  color: var(--accent-text);
  font-family: DelaGothicOne;
  font-size: 1rem;
  @media (min-width: 500px) {
    font-size: 2rem;
  }
  font-weight: 500;
  line-height: 1.6;
`;
export const Answer = styled.div`
  color: var(--accent-text);
  font-family: DelaGothicOne;

  font-size: 0.7rem;
  @media (min-width: 500px) {
    font-size: 1.2rem;
  }
  font-weight: 500;
  line-height: 1.6;
`;

export const FaqTitle = styled.p`
  color: var(--accent-text);
  font-family: DelaGothicOne;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.6;
`;

export const StyledFaqTitle = styled.p`
  color: var(--primary-text);
  font-size: 24px 0;
  text-align: center;
  text-align: "center";
  font-weight: "bold";
  color: deepskyblue;
  line-height: 2;
`;

export const StyledKeyVisual = styled.img`
  width: 100%;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
  transition: width 0.5s;
  transition: height 0.5s;
`;

function Faq() {
  return (
    <BoxList>
      <Grid container direction="column">
        <Grid item container>
          <Grid xs={12} sm={12}>
            <FaqTitle
              id="faq"
              style={{
                textAlign: "center",
              }}
            >
              {"Q＆A"}
            </FaqTitle>
            <s.SpacerLarge />
          </Grid>

          <Grid xs={6} sm={3}>
            <Box
              sx={{
                width: 1 / 1.25,
                // height: 250,
                mx: "auto",
                // backgroundImage: 'url(/config/images/kousei.jpeg)',
                // backgroundSize: 250,
                // "&:hover": {
                //   backgroundColor: "primary.main",
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}
            >
              <Question>{"When Mint?"}</Question>
              <Answer>
                {"2023/03/25"}
                <br />
                {"AL1:"}
                <br />
                {"- 8:00pm(JST)"}
                <br />
                {"AL2:"}
                <br />
                {"- 9:00pm(JST)"}
                <br />
                {"AL3:"}
                <br />
                {"- 10:00pm(JST)"}
                {/* <br />
                {"Public Sale:"}
                <br />
                {" - 11:30pm(JST)"}
                <br /> */}
              </Answer>
            </Box>
          </Grid>
          <Grid xs={6} sm={3}>
            <Box
              sx={{
                width: 1 / 1.25,
                // height: 250,
                mx: "auto",
                // backgroundImage: 'url(/config/images/kousei.jpeg)',
                // backgroundSize: 250,
                // "&:hover": {
                //   backgroundColor: "primary.main",
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}
            >
              <Question>{"How much?"}</Question>
              <Answer>
                {"AL1,AL2,AL3:"}
                <br />
                {"- Free Mint"}
                {/* <br />
                {"Public Sale:"}
                <br />
                {"- 0.002ETH"} */}
                <br />
              </Answer>
            </Box>
          </Grid>
          <Grid xs={6} sm={3}>
            <Box
              sx={{
                width: 1 / 1.25,
                // height: 250,
                mx: "auto",
                // backgroundImage: 'url(/config/images/kousei.jpeg)',
                // backgroundSize: 250,
                // "&:hover": {
                //   backgroundColor: "primary.main",
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}
            >
              <Question>{"How many?"}</Question>
              <Answer>
                {"TotalSupply:"}
                <br />
                {"- 10000"}
                {/* <br />
                {"Public Sale:"}
                <br />
                {"- 2 max per wallet"}
                <br /> */}
              </Answer>
            </Box>
          </Grid>
          <Grid xs={6} sm={3}>
            <Box
              sx={{
                width: 1 / 1.25,
                // height: 250,
                mx: "auto",
                // backgroundImage: 'url(/config/images/kousei.jpeg)',
                // backgroundSize: 250,
                // "&:hover": {
                //   backgroundColor: "primary.main",
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}
            >
              <Question>{"When Reveal?"}</Question>
              <Answer>
                {"TBD 8:00pm(JST)"}
                <br />
              </Answer>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <s.SpacerLarge />
    </BoxList>
  );
}

export default Faq;
